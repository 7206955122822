import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client'; // socket.io 클라이언트 라이브러리
import './ParticipantScreen.css';

const ParticipantScreen = () => {
  const [error, setError] = useState(null);
  const [hasCamera, setHasCamera] = useState(true);
  const [isRemoteActive, setIsRemoteActive] = useState(false);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const socket = useRef(null);
  const [transport, setTransport] = useState(null);

  useEffect(() => {
    // 서버 연결
    socket.current = io('wss://localhost:3000', { transports: ['websocket'] });

    socket.current.on('connect', () => {
      console.log('Connected to server');
      console.log('Socket ID:', socket.current.id);  // 소켓 연결 ID 출력

      // joinRoom 이벤트로 방에 참여
      const roomName = '1';  // 예시로 방 이름을 1로 설정
      const role = 'participant';  // 예시로 역할을 participant로 설정
      socket.current.emit('joinRoom', { roomName, role });  // roomName과 role을 전달
    });

    socket.current.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    socket.current.on('transportCreated', (createdTransport) => {
      console.log('Transport created:', createdTransport);
      setTransport(createdTransport);
    });

    socket.current.on('consumeCreated', (consumer) => {
      console.log('Consume created:', consumer);
      // 비디오 스트림 받기
      const stream = new MediaStream();
      stream.addTrack(consumer.track);
      remoteVideoRef.current.srcObject = stream;
      setIsRemoteActive(true);
    });

    // 클라이언트 연결 종료 시 처리
    return () => {
      console.log('Cleaning up socket connection');
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    const getVideoStream = async () => {
      try {
        console.log('Requesting video stream...');
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }

        if (!stream) {
          setHasCamera(false);
        }

        // 스트림을 서버로 보내기 위해 트랜스포트를 생성
        console.log('Emitting createTransport event...');
        socket.current.emit('createTransport');
      } catch (error) {
        setError('카메라나 마이크에 접근할 수 없습니다.');
        console.error('Error accessing media devices:', error);
      }
    };

    getVideoStream();
  }, []);

  const sendStream = (stream) => {
    if (!transport) {
      console.error('Transport is not created yet');
      return;
    }

    // 스트림을 서버로 보내기
    const track = stream.getTracks()[0];
    const rtpParameters = track.getParameters();

    console.log('Sending stream with RTP parameters:', rtpParameters);
    socket.current.emit('produce', {
      transportId: transport.id,
      kind: track.kind,
      rtpParameters,
    });
  };

  return (
    <div className="participant-screen">
      <div className="status">
        {error && <p className="error">{error}</p>}
      </div>
      <div className="video-grid">
        {/* 첫 번째 비디오: 내 화면 */}
        <div className="video-wrapper">
          {hasCamera ? (
            <video
              ref={localVideoRef}
              autoPlay
              playsInline
              muted
              className="video-stream"
            />
          ) : (
            <div className="no-camera">
              <p>카메라 없음</p>
            </div>
          )}
        </div>

        {/* 두 번째 비디오: 다른 참가자의 화면 */}
        <div className="video-wrapper">
          {isRemoteActive ? (
            <video
              ref={remoteVideoRef}
              autoPlay
              playsInline
              className="video-stream"
            />
          ) : (
            <div className="waiting-message">
              <p>대기 중</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParticipantScreen;
