import React from 'react';
import LeftSidebar from '../LeftSidebar/LeftSidebar.jsx';
import MainContent from '../MainContent/MainContent.jsx';
import RightSidebar from '../RightSidebar/RightSidebar.jsx';

const Room = ({ roomNumber, role }) => {
  return (
    <div className="room">
      <div className="app">
        <LeftSidebar />
        <MainContent roomNumber={roomNumber} role={role} />
        <RightSidebar />
      </div>
    </div>
  );
};

export default Room;
