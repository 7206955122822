import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LeftSidebar from '../LeftSidebar/LeftSidebar.jsx';
import RoomModal from '../RoomModal/RoomModal.jsx';
import RoomList from '../RoomList/RoomList.jsx';
import VideoChat from '../VideoChat/VideoChat.jsx';
import './Home.css';

const Home = () => {
  const currentUserId = 'testUser';

  // 하드코딩된 방 정보
  const [rooms, setRooms] = useState([
    {
      roomNumber: '1',
      name: '토론방 1',
      createdBy: 'user1',
      participants: [
        { userId: 'user1', username: 'User1' },
        { userId: 'user2', username: 'User2' },
      ],
    },
    {
      roomNumber: '2',
      name: '토론방 2',
      createdBy: 'user2',
      participants: [
        { userId: 'user3', username: 'User3' },
        { userId: 'user4', username: 'User4' },
      ],
    },
    {
      roomNumber: '3',
      name: '토론방 3',
      createdBy: 'user3',
      participants: [
        { userId: 'user5', username: 'User5' },
        { userId: 'user6', username: 'User6' },
      ],
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // 방 생성 함수
  const createRoom = (title) => {
    // 새로운 방을 생성
    const newRoom = {
      roomNumber: (rooms.length + 1).toString(), // 새로운 방 번호
      name: title,
      createdBy: currentUserId,
      participants: [
        { userId: currentUserId, username: currentUserId }, // 생성자가 참가자로 추가
      ],
    };

    // 새 방을 기존 방 목록에 추가
    setRooms((prevRooms) => [...prevRooms, newRoom]);
  };

  return (
    <div className="home">
      <LeftSidebar />
      <div className="home-content">
        <h1>토론방 목록</h1>
        <RoomList rooms={rooms} />
        <button onClick={openModal}>토론방 생성하기</button>
        <div>
          <Link to="/login">로그인</Link>
          <Link to="/signup">회원가입</Link>
        </div>
        {isModalOpen && (
          <RoomModal onClose={closeModal} onCreateRoom={createRoom} />
        )}

        {/* 비디오 컴포넌트 추가 */}
        <div className="video-chat-container">
          <h2>참여자 비디오</h2>
          <VideoChat roomNumber={1} role="participant" />

          <h2>관전자 비디오</h2>
          <VideoChat roomNumber={1} role="observer" />
        </div>
      </div>
    </div>
  );
};

export default Home;
