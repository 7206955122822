import { useEffect, useState } from 'react';
import { mediaSocket as socket } from '../utils/socket.js';

const useSocket = (onConnectionSuccess, roomNumber, role) => {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    // 소켓 연결 및 초기화
    socket.on('connection-success', ({ socketId }) => {
      console.log('Connected to server with socket ID:', socketId);
      setIsConnected(true);
      if (onConnectionSuccess) onConnectionSuccess();
    });

    socket.emit('joinRoom', { roomNumber, role }, (response) => {
      if (response.error) {
        console.error('Join room error:', response.error);
      } else {
        console.log('Join room success:', response);
      }
    });
    
    return () => {
      // 소켓 해제
      socket.disconnect();
    };
  }, [onConnectionSuccess, roomNumber, role]);

  return socket;
};

export default useSocket;
