import React, { useEffect, useRef } from 'react';
import './VideoChat.css';
import useMediasoupClient from '../../hooks/useMediasoupClient';
import { mediaSocket as socket } from '../../utils/socket.js';

const VideoChat = ({ roomName, role }) => {
  const localVideoRef = useRef();
  const remoteVideoContainerRef = useRef();

  const { joinRoom, signalNewConsumerTransport } = useMediasoupClient(roomName, role, localVideoRef.current?.srcObject);

  useEffect(() => {
    joinRoom();

    const handleNewProducer = ({ producerId }) => {
      signalNewConsumerTransport(producerId);
    };

    socket.on('new-producer', handleNewProducer);

    return () => {
      socket.off('new-producer', handleNewProducer);
    };
  }, [joinRoom, signalNewConsumerTransport]);

  // 원격 비디오 스트림을 처리하는 함수
  const handleRemoteStream = (stream) => {
    const remoteVideo = document.createElement('video');
    remoteVideo.srcObject = stream;
    remoteVideo.autoplay = true;
    remoteVideo.playsInline = true;
    remoteVideo.classList.add('remote-video');
    remoteVideoContainerRef.current.appendChild(remoteVideo);
  };

  useEffect(() => {
    socket.on('new-consumer', handleRemoteStream);

    return () => {
      socket.off('new-consumer', handleRemoteStream);
    };
  }, []);

  return (
    <div className="video-chat">
      {role !== 'observer' && <video ref={localVideoRef} autoPlay muted className="local-video" />}
      <div ref={remoteVideoContainerRef} className="remote-videos"></div>
    </div>
  );
};

export default VideoChat;
